import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Image } from './../../../../static/theme'
import { Definit__logo, Definit__iso } from './../../../../static/images'

const BrandDefault = ({ component, size, align, gutterBottom }) => {

  let Component = component ? component : 'h1'

  return (
    <div className={
      classNames(
        'ds-brand-default',
        size ? `ds-brand-default__align-${ size }` : null,
        align ? `ds-brand-default__align-${ align }` : null,
        gutterBottom ? `ds-brand-default__mb` : null
      )
    }>
      <Component className={
        classNames('ds-brand-default__component')
      }>
        <Image src={ Definit__iso } alt="Definit - Depilación Láser Definitiva by Espaço Laser" fluid/>
      </Component>
    </div>
  )

}

BrandDefault.propTypes = {
  component: PropTypes.oneOf(['h1','h2','h3','h4','h5','h6']),
  align: PropTypes.oneOf(['center']),
  gutterBottom: PropTypes.bool
}

export default BrandDefault
