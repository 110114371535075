import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const HeadlineFirst = ({ component, title, align, gutterBottom }) => {

  let Component = component ? component : 'h2'

  return (
    <div className={
      classNames(
        'ds-headline-first',
        align ? `ds-headline-first__align-${ align }` : null,
        gutterBottom ? `ds-headline-first__mb` : null
      )
    }>
      <Component className={
        classNames('ds-headline-first__component')
      } dangerouslySetInnerHTML={{ __html: title }}/>
    </div>
  )

}

HeadlineFirst.propTypes = {
  component: PropTypes.oneOf(['h1','h2','h3','h4','h5','h6']),
  title: PropTypes.string.isRequired
}

export default HeadlineFirst
