import React from 'react';
import { Route } from 'react-router-dom';

const Layout = ({children, ...rest}) => {
  return (
    <div className="ds-layout">
      { children }
    </div>
  )
}

const DefaultLayout = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <Layout classes= { rest.classes }>
          <Component {...matchProps} />
      </Layout>
    )} />
  )
};

export default DefaultLayout;
