import React from 'react'

const Footer = ({copy}) => {

  return (
    <div className="ds-footer">
      <div className="ds-footer__rrss">
        <a href="https://www.facebook.com/Definitlaser" target="_blank" rel="noopener noreferrer">
          <i className="fa fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/definit_ar/" target="_blank" rel="noopener noreferrer">
          <i className="fa fa-instagram"></i>
        </a>
        <a href="https://www.youtube.com/channel/UCPKKjjbtREIbVXogyqLtFGw" target="_blank" rel="noopener noreferrer">
          <i className="fa fa-youtube"></i>
        </a>
      </div>
      <div className="ds-footer__copy">
        { copy ? (
          <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html : copy }}/>
          </React.Fragment>
        ) : (

          <React.Fragment>
            <p>
              <strong>(*) PROMOCIÓN VÁLIDA PARA MAYORES DE 18 AÑOS USUARIOS DE TARJETA DE CRÉDITO.</strong> Sujeta a aprobación médica o validación previa. No acumulativa con otros descuentos y/o promociones.
            </p>
            <p>
              <strong>(**) SUJETO A VALIDACIÓN PREVIA. NO ACUMULABLE CON OTRAS PROMOCIONES.</strong>
            </p>
          </React.Fragment>

        )}

      </div>
    </div>
  )

}

export default Footer
