import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import { DefaultLayout } from './components/layout'
import { HomePage, PromotionPage, PromotionPage__2x1, PromotionPage__Ago2019 } from './pages'

function App() {
  return (
    <React.Fragment>
      <HashRouter>
        <Switch>
          <DefaultLayout path="/" component={ HomePage } exact/>
          <DefaultLayout path="/aprovecha-esta-super-promo" component={ HomePage } exact/>
          <DefaultLayout path="/promo-hombres" component={ HomePage } exact/>
          <DefaultLayout path="/llegamos-a-unicenter" component={ HomePage } exact/>
          <DefaultLayout path="/promocion-ahora-12" component={ HomePage } exact/>
          <DefaultLayout path="/sorteo" component={ HomePage } exact/>
          <DefaultLayout path="/promo-especial" component={ HomePage } exact/>
          // <DefaultLayout path="/big-sale" component={ HomePage } exact/>
          // <DefaultLayout path="/hello-spring-40-off" component={ HomePage } exact/>
          // <DefaultLayout path="/mes-del-amigo" component={ PromotionPage } exact/>
          // <DefaultLayout path="/promo-2x1" component={ PromotionPage__2x1 } exact/>
          // <DefaultLayout path="/promociones-agosto" component={ PromotionPage__Ago2019 } exact/>
          <Redirect to="/" />
        </Switch>
      </HashRouter>
    </React.Fragment>
  );
}

export default App;
