import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const HeadlineSecond = ({ component, title, align, gutterBottom }) => {

  let Component = component ? component : 'h3'

  return (
    <div className={
      classNames(
        'ds-headline-second',
        align ? `ds-headline-second__align-${ align }` : null,
        gutterBottom ? `ds-headline-second__mb` : null
      )
    }>
      <Component className={
        classNames('ds-headline-second__component')
      } dangerouslySetInnerHTML={{ __html: title }}/>
    </div>
  )
}

HeadlineSecond.propTypes = {
  component: PropTypes.oneOf(['h1','h2','h3','h4','h5','h6']),
  title: PropTypes.string.isRequired
}

export default HeadlineSecond
