import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Form } from './../../../../static/theme';

const FormInput = ({
  input,
  label,
  placeholder,
  type,
  inline,
  required,
  meta: { touched, error, warning }, ...other
}) => (
  <Form.Group className={
    classNames(
      inline ? "form-inline form-group__inline" : null
    )
  }>
    { label && (
      <Form.Label>{ label } { required ? '*' : null }</Form.Label>
    )}

    <Form.Control {...input} placeholder={ placeholder ? placeholder : null } type={ type } { ...other } className="form-control"/>
    {touched &&
      ((error && <span className="form-group__error">{error}</span>) ||
        (warning && <span className="form-group__warning">{warning}</span>))}
  </Form.Group>
)

FormInput.propTypes = {
  type: PropTypes.oneOf(['text','number','date', 'email', 'hidden']),
  placeholder: PropTypes.string,
  inline: PropTypes.bool
};

export default FormInput;
