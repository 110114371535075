import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Image } from './../../../../static/theme'

const PromotionDefault = ({ image, title, align, gutterBottom }) => {

  return (
    <div className={
      classNames(
        'ds-promotion-default',
        align ? `ds-promotion-default__align-${ align }` : null,
        gutterBottom ? `ds-promotion-default__mb` : null
      )
    }>
      <Image src={ image } alt={ title } fluid/>
    </div>
  )

}

PromotionDefault.propTypes = {
  align: PropTypes.oneOf(['center']),
  gutterBottom: PropTypes.bool
}

export default PromotionDefault
