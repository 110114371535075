import React from 'react'
import { withRouter } from 'react-router'
import { Row, Col } from './../../static/theme'

import { BrandDefault } from './../../components/ui/brand'
import { HeadlineFirst, HeadlineSecond } from './../../components/ui/headline';
import { TeaserDefault } from './../../components/ui/teaser';
import { PromotionDefault } from './../../components/ui/promotion'
import { Footer } from './../../components/shared';
import { FormComponent } from './../../scenes'

import {
    PromotionGeneric__teaserDesktop,
    PromotionGeneric__teaserMobile,
    PromotionPage__teaserDesktop,
    PromotionPage__teaserMobile,
    PromotionPage__discount,
    PromotionPage__teaserDesktop_NewLocal,
    PromotionPage__teaserMobile_NewLocal,
    PromotionPage__discount_NewLocal,
    PromotionGeneric__teaserDesktop_12Coutas,
    PromotionGeneric__teaserMobile_12Coutas,
    PromotionGeneric__discount_12Coutas,
    PromotionPage__teaserDesktop_Special,
    PromotionPage__teaserMobile_Special,
    PromotionPage__discount_Special,
    PromotionPage__teaserDesktop_Special2,
    PromotionPage__teaserMobile_Special2,
    PromotionPage__discount_Special2,
    PromotionPage__teaserDesktop_Sorteo,
    PromotionPage__teaserMobile_Sorteo,
    PromotionPage__discount_Sorteo
} from './../../static/images'

class HomePage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading:         false,
      headlineFirst:   null,
      headlineSecond:  null,
      headlineDiscount:  null,
      leadSource:      null,
      imageDesktop:    PromotionGeneric__teaserDesktop,
      imageMobile:     PromotionGeneric__teaserMobile,
      copy:            null
    }
  }

  componentDidMount(){

    this.handleToInfo();

  }

  componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        this.handleToInfo();
      }
  }

  /*
  ** @Function
  */

  handleToInfo = () => {
    if(this.props.location.pathname === '/aprovecha-esta-super-promo'){
      window.location = 'https://definit.com.ar/promociones/aprovecha-esta-super-promo';
    }else if(this.props.location.pathname === '/promo-hombres'){
      window.location = 'https://definit.com.ar/acciones/referidos';
    }else if(this.props.location.pathname === '/llegamos-a-unicenter'){
      window.location = 'https://definit.com.ar/promociones/aprovecha-esta-super-promo';
    }else if(this.props.location.pathname === '/promocion-ahora-12'){
      window.location = 'https://definit.com.ar/promociones/ahora-12';
    }else if(this.props.location.pathname === '/promo-especial'){
      window.location = 'https://definit.com.ar/promociones/aprovecha-esta-super-promo';
    }else if(this.props.location.pathname === '/sorteo'){
      window.location = 'https://definit.com.ar/promociones/aprovecha-esta-super-promo';
    }else if(this.props.location.pathname === '/cyber-sale'){
      window.location = 'https://definit.com.ar/promociones/aprovecha-esta-super-promo';
    }else if(this.props.location.pathname === '/black-sale'){
      window.location = 'https://definit.com.ar/promociones/aprovecha-esta-super-promo';
    }else if(this.props.location.pathname === '/big-sale'){
      window.location = 'https://definit.com.ar/promociones/aprovecha-esta-super-promo';
    }else{
      window.location = 'https://definit.com.ar/acciones/referidos';
    }
  }

  /*
  ** @Function
  */

  handleToState = (headlineFirst, headlineSecond, headlineDiscount, leadSource, imageDesktop, imageMobile, copy) => {

    this.setState({
      headlineFirst:  headlineFirst,
      headlineSecond: headlineSecond,
      headlineDiscount: headlineDiscount,
      leadSource:     leadSource,
      loading:        true,
      imageDesktop:    imageDesktop,
      imageMobile:     imageMobile,
      copy:            copy
    });

  }

  render() {

    const { loading, headlineFirst, headlineSecond, headlineDiscount, leadSource, imageDesktop, imageMobile, copy } = this.state;

    return (
      <div className="ds-index">
        <Row>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <TeaserDefault imageDesktop={ imageDesktop } imageMobile={ imageMobile } position="center top"/>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>

            <div className="ds-form-style-column">

              <BrandDefault/>

              { loading && (
                <React.Fragment>

                  { headlineDiscount && (
                    <PromotionDefault
                      title=""
                      image={ headlineDiscount }
                      align="center"
                      gutterBottom
                    />
                  )}

                  { headlineFirst && (
                    <HeadlineFirst title={ headlineFirst } align="center" gutterBottom/>
                  )}

                  { headlineSecond && (
                    <HeadlineSecond title={ headlineSecond } align="center" gutterBottom/>
                  )}

                  {/* promotion form */}
                  <FormComponent leadSource={ leadSource }/>
                </React.Fragment>
              )}

              {/* footer */}
              <Footer copy={
                copy
              }/>

            </div>

          </Col>
        </Row>
      </div>
    )

  }

}

export default withRouter(HomePage)
