import React from 'react'
import { Row, Col } from './../../static/theme'
import { BrandDefault } from './../../components/ui/brand'
import { PromotionDefault } from './../../components/ui/promotion'
import { HeadlineSecond } from './../../components/ui/headline';
import { TeaserDefault } from './../../components/ui/teaser';
import { Footer } from './../../components/shared';
import { FormComponent } from './../../scenes'

import { PromotionPage__teaserDesktop_Ago_2019, PromotionPage__teaserMobile_Ago_2019, PromotionPage__discount_Ago_2019 } from './../../static/images'

const PromotionPage__Ago2019 = () => (

  <div className="ds-index">

    {/* show only sizes > md */}
    <div className="ds-header-absolute">
      <BrandDefault/>
    </div>

    <div className="ds-container">
      <Row>

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <TeaserDefault imageDesktop={ PromotionPage__teaserDesktop_Ago_2019 } imageMobile={ PromotionPage__teaserMobile_Ago_2019 } position="center bottom"/>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>

          {/* show only sizes > md */}
          <div className="ds-header-relative">
            <BrandDefault/>
          </div>

          {/* right column */}
          <div className="ds-form-style-column">

            {/* promotion image */}
            <PromotionDefault
              title="Ingresá tus datos y accedé a esta promo"
              image={ PromotionPage__discount_Ago_2019 }
              align="center"
              gutterBottom
            />

            {/* promotion text */}
            <HeadlineSecond title="Ingresá tus datos y accedé a esta promo" align="center" gutterBottom/>

            {/* promotion form */}
            <FormComponent leadSource="Promociones_Ago_2019"/>

            {/* footer */}
            <Footer/>

          </div>

        </Col>
      </Row>
    </div>
  </div>

)

export default PromotionPage__Ago2019
