import moment from 'moment'

function isLetters (value) {
  return !/^[a-zA-ZáéíóúñçüöÁÉÍÓÚÑÇÜÖ\s'"]*$/.test(value);
}

function isEmail (value) {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
}

function isNumber (value) {
  return typeof value === 'number';
}

function isObjectEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function numberStartWithZero (value) {
  return /^0/.test(value);
}

function numberStartWithFifthteen (value) {
  return /^15/.test(value);
}

function numberLength (value) {
  // Total de digitos 13, menos el 0 del codigo + 15 del ceular
  return 10 - value.length;
}

function minYearsRequired (yearsToSubtract, format) {
  let date = moment().subtract(yearsToSubtract, 'years');
  return format ? date.format(format) : date.format();
}

function filterUtms (utms) {

  let isWhat = utms.gclid ? true : false;

  let gClid       = isWhat ? utms.gclid    : undefined;
  let utmSource   = isWhat ? utms.source   : utms.utm_source;
  let utmMedium   = isWhat ? utms.medium   : utms.utm_medium;
  let utmCampaign = isWhat ? utms.campaign : utms.utm_campaign;
  let utmContent  = isWhat ? utms.content  : utms.utm_content;

  let _utms = {};

  if(gClid){
    _utms = Object.assign({ gclid : gClid }, _utms);
  }

  if(utmSource){
    _utms = Object.assign({ utm_source : utmSource }, _utms);
  }

  if(utmMedium){
    _utms = Object.assign({ utm_medium : utmMedium }, _utms);
  }

  if(utmCampaign){
    _utms = Object.assign({ utm_campaign : utmCampaign }, _utms);
  }

  if(utmContent){
    _utms = Object.assign({ utm_content : utmContent }, _utms);
  }

  return _utms;

}

function replaceKeys(values, toFind, toSend){

  let toRemplace = Object.entries(values).reduce((u, [n, v]) => {

    let find = toFind.find( r => r.find === n );

    if(find){
      u[find.replace] = v;
    }else{
      u[n] = v;
    }

    return u;

  }, {});

  return toRemplace;

}

export { isEmail, isNumber, isLetters, isObjectEmpty, numberLength, numberStartWithZero, numberStartWithFifthteen, minYearsRequired, filterUtms, replaceKeys }
