import { createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ReduxThunk from 'redux-thunk';

const Lead = (state = {}, action) => {

  let newState = Object.assign({}, state);

  switch(action.type){
    case 'LEAD_CREATE':
      newState = action.data;
      return newState;
    case 'LEAD_CLEAR':
      newState = {};
      return newState;
    default:
      return state;
  }

}

const reducers = combineReducers({
  Lead: Lead,
  form: formReducer
});

const Store = createStore(reducers, applyMiddleware(ReduxThunk));

export default Store;
