const VALIDATION = {
  required:               'Campo requerido.',
  minlength:              'Cantidad mínima de caracteres erronea.',
  maxlength:              'Cantidad máxima de caracteres superada.',
  equallength:            'Cantidad de caracteres erronea.',
  email:                  'Email inválido.',
  latters:                'Sólo caracteres válidos y espacios.',
  number:                 'Sólo números.',
  number_with_zero:       'Sólo números, sin el "0" inicial.',
  number_with_fifthteen:  'Sólo números, sin el "15" inicial.'
}

export default VALIDATION;
