import React from 'react';
import { reduxForm, Field, change } from 'redux-form';
import request from 'request';
import { Button, Alert, Row, Col } from './../../static/theme';
import { FormInput } from './../../components/ui/form';
import { isEmail, isLetters, isNumber, numberLength, numberStartWithZero, numberStartWithFifthteen, replaceKeys } from './../../utilities/utils';

import VALIDATION from './../../config/validation'

// @Validation
const validate = values => {

  const errors = {};

  // @first_name
  if (!values.first_name) {
    errors.first_name = VALIDATION.required;
  }else if (isLetters(values.first_name)){
    errors.first_name = VALIDATION.latters;
  } else if(values.first_name.length > 40){
    errors.first_name = `${ VALIDATION.maxlength } max. 40 caracteres.`;
  }

  // @last_name
  if (!values.last_name) {
    errors.last_name = VALIDATION.required;
  } else if (isLetters(values.last_name)){
    errors.last_name = VALIDATION.latters;
  } else if(values.last_name.length > 40){
    errors.last_name = `${ VALIDATION.maxlength } max. 40 caracteres.`;
  }

  // @Email
  if (!values.email) {
    errors.email = VALIDATION.required;
  }else if (isEmail(values.email)){
    errors.email = VALIDATION.email;
  }else if (values.email.length > 320){
    errors.email = `${ VALIDATION.maxlength } max. 320 caracteres.`;
  }

  // @CellphoneCode
  if (!values.cellphone_code) {
    errors.cellphone_code = VALIDATION.required;
  } else if (values.cellphone_code.length < 2) {
    errors.cellphone_code = `${ VALIDATION.minlength } min. 2 dígitos.`;
  } else if (values.cellphone_code.length > 4) {
    errors.cellphone_code = `${ VALIDATION.maxlength } max. 4 dígitos.`;
  } else if (!isNumber(Number(values.cellphone_code))) {
    errors.cellphone_code = VALIDATION.number;
  } else if (numberStartWithZero(values.cellphone_code)) {
    errors.cellphone_code = VALIDATION.number_with_zero;
  }

  // @Cellphone
  if (!values.cellphone) {
    errors.cellphone = VALIDATION.required;
  } else if (!isNumber(Number(values.cellphone))) {
    errors.cellphone = VALIDATION.number;
  } else if (numberStartWithFifthteen(values.cellphone)) {
    errors.cellphone = VALIDATION.number_with_fifthteen;
  } else if (
    values.cellphone_code && (values.cellphone_code.length >= 2 && values.cellphone_code.length <= 4)
    &&
    (values.cellphone.length < numberLength(values.cellphone_code) || values.cellphone.length > numberLength(values.cellphone_code))
  ){
    errors.cellphone = `El número de celular debe completarse con ${ numberLength(values.cellphone_code) } dígitos.`;
  } else if ( !values.cellphone_code && values.cellphone.length < 6) {
      errors.cellphone = `${ VALIDATION.minlength } min. 6 dígitos.`;
  } else if ( !values.cellphone_code && values.cellphone.length > 8) {
      errors.cellphone = `${ VALIDATION.maxlength } max. 8 dígitos.`;
  }

  // @document_number
  // if (!values.document_number) {
  //   errors.document_number = VALIDATION.required;
  // }else if(values.document_number.length < 7){
  //   errors.document_number = `${ VALIDATION.minlength } min. 7 caracteres.`;
  // }else if(values.document_number.length > 8){
  //   errors.document_number = `${ VALIDATION.maxlength } max. 8 caracteres.`;
  // }

  return errors;

}

let toReplaceOnSaveLead = [
  { find: "first_name",       replace: "First Name" },
  { find: "last_name",        replace: "Last Name" },
  { find: "email",            replace: "Email" },
  //{ find: "document_number",  replace: "LEADCF56" },
  { find: "cellphone_number", replace: "Mobile" },
  { find: "lead_source",      replace: "Lead Source" },
  { find: "product_selected", replace: "LEADCF9" }
];

let toMergeOnSaveLead = {
  "xnQsjsdp":         "c37ab8a27add97f70590bf9632860a1bb24abe1ba03cc1f4e27763909432c43c",
  "zc_gad":           "",
  "xmIwtLD":          "d7d8e216522bc176a903a7798eb80130846c9bf84a51d9c8b8cecb5857e71a29",
  "actionType":       "TGVhZHM=",
  "returnURL":        ""
};

// @Component
class FormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submit:  false,
      success: false,
      error:   false
    };

    this.form = React.createRef();

  }

  /*
  ** @Event
  ** @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount () {

    if(this.props.leadSource){
      this.props.dispatch(change('FormComponent', 'lead_source', this.props.leadSource ));
    }else{
      this.props.dispatch(change('FormComponent', 'lead_source', 'Generico' ));
    }


  }

  /*
  ** @Event
  ** @Description: Submit form
  */

  handleSubmit = (params) => {

    // ** @State
    // ** @Description: Submit disabled
    this.setState({ submit: true });

    params.cellphone_number = `0${ params.cellphone_code} 15${ params.cellphone }`;

    let values = replaceKeys(params, toReplaceOnSaveLead);
        values = Object.assign(values, toMergeOnSaveLead);

        //
    request({
       method: 'POST',
       uri: `${ process.env.REACT_APP_CRM_ENDPOINT }/WebToLeadForm`,
       form: values,
       headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
     },(error, response, body) => {

       // ** @Error
       // ** @Description: El servicio de Zoho dejo un error.
       if (error) {
         this.setState({ error: true, submit: false });
       }

       //console.log(response);

       if (response.statusCode === 200) {

         // ** @Success
         // ** @Description: El servicio de Zoho guardo el lead correctamente.
         this.setState({ success: true, submit: false });

         // @Ga Event
         window.gaEvent('General', 'Boton', 'Lead');

       } else {

         this.setState({ error: true, submit: false });

       }


     });


  }

  render(){

    const { submit, success, error } = this.state;
    const { handleSubmit, invalid } = this.props;

    return(
      <div className="ds-form">

        <div className="ds-form__element">

          { error && (
            <Alert variant="warning">
              Hubo un error interno. Por favor intenta más tarde.
            </Alert>
          )}

          { !success ? (

            <form noValidate autoComplete="off" onSubmit={ handleSubmit( this.handleSubmit ) } name="WebToLeads3756050000000927018" acceptCharset="UTF-8">

                <Field
                  inline
                  label="NOMBRE:"
                  name="first_name"
                  component={ FormInput }
                  type="text"
                  required
                />

                <Field
                  inline
                  label="APELLIDO:"
                  name="last_name"
                  component={ FormInput }
                  type="text"
                  required
                />

                <Field
                  inline
                  label="EMAIL:"
                  name="email"
                  component={ FormInput }
                  type="email"
                  required
                />

                {/*
                <Field
                  inline
                  label="DNI:"
                  name="document_number"
                  component={ FormInput }
                  type="number"
                  required
                />
                */}

                <Row>
                  <Col xl={6} lg={6} md={6} sm={5} xs={12}>
                    <Field
                      inline
                      label="CÓDIGO DE ÁREA:"
                      name="cellphone_code"
                      component={ FormInput }
                      type="number"
                      onKeyUp={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                      required
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={7} xs={12}>
                    <Field
                      inline
                      label="CELULAR:"
                      name="cellphone"
                      component={ FormInput }
                      type="number"
                      onKeyUp={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                      required
                    />
                  </Col>
                </Row>

                <p className="ds-form__disclaimer">
                  <small>Los datos que ingresás son confidenciales y vamos a usarlos solo para comunicarte beneficios y promociones.</small>
                </p>

                <div className="ds-form__buttons">
                  <Button type="submit" variant="success" size="lg" disabled={ invalid || submit }>ENVIAR</Button>
                </div>

            </form>

          ) : (

            <div className="ds-form__thanks">
              <span>Gracias.</span>
            </div>

          )}

        </div>


      </div>
    )

  }

}

FormComponent = reduxForm({
  form: "FormComponent",
  validate,
  initialValues: {
    // first_name: "german",
    // last_name: "glock",
    // email: "a@as.com",
    // document_number: "32717098"

  }
})(FormComponent);

export default FormComponent
