import React from 'react'
import { Row, Col } from './../../static/theme'
import { BrandDefault } from './../../components/ui/brand'
import { PromotionDefault } from './../../components/ui/promotion'
import { HeadlineSecond } from './../../components/ui/headline';
import { TeaserDefault } from './../../components/ui/teaser';
import { Footer } from './../../components/shared';
import { FormComponent } from './../../scenes'

import { PromotionPage__discount, PromotionPage__teaserDesktop, PromotionPage__teaserMobile } from './../../static/images'

const PromotionPage = () => (

  <div className="ds-index">

    {/* show only sizes > md */}
    <div className="ds-header-absolute">
      <BrandDefault/>
    </div>

    <div className="ds-container">
      <Row>

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <TeaserDefault imageDesktop={ PromotionPage__teaserDesktop } imageMobile={ PromotionPage__teaserMobile } position="center bottom"/>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>

          {/* show only sizes > md */}
          <div className="ds-header-relative">
            <BrandDefault/>
          </div>

          {/* right column */}
          <div className="ds-form-style-column">

            {/* promotion image */}
            <PromotionDefault
              title="Mes del amigo, ¡Vení con tu amig@! 30% OFF en la zona que elijan."
              image={ PromotionPage__discount }
              align="center"
              gutterBottom
            />

            {/* promotion text */}
            <HeadlineSecond title="Ingresá tus datos y accedé a esta <br/>gran promo con tu amig@" align="center" gutterBottom/>

            {/* promotion form */}
            <FormComponent leadSource="30% off - mes del amigo"/>

            {/* footer */}
            <Footer/>

          </div>

        </Col>
      </Row>
    </div>
  </div>

)

export default PromotionPage
