import React from 'react'
import PropTypes from 'prop-types'
import { Image } from './../../../../static/theme'

const TeaserDefault = ({ imageDesktop, imageMobile, position }) => {

  let style = {
    backgroundImage: `url(${imageDesktop})`,
    backgroundPosition: position ? position : null
  };

  return (
    <div className="ds-teaser-style-column">
      <span style={ style }></span>
      <Image src={ imageMobile }/>
    </div>
  )

}

TeaserDefault.propTypes = {
  imageDesktop: PropTypes.string.isRequired
}

export default TeaserDefault
